import * as yup from "yup";

const itemsSchema = yup.object().shape({
  name: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  item_type: yup
    .string()
    .oneOf(["CANDY", "CHIPS", "DRINK", "COOKIE"], "Invalid item type")
    .required("Type is required"),
  imageUrl: yup.string().required("Featured image is required"),
});

export { itemsSchema };
