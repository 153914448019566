import { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import ResultFilterBar from "../../../components/general/ResultFilterBar";
import Tableform from "../../../components/general/Tableform";
import { API } from "../../../api";
import { errorToast, successToast } from "../../../hooks/useToast";
import Loader from "../../../components/general/Loader";
import { productColumns } from "../../../data/ProductColumns";

const Products = () => {
  const [itemPerPage, setitemPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState(null);
  const [productTypeFilter, setProductTypeFilter] = useState("PHYSICAL"); // State for product type filter
  const [loading, setLoading] = useState(true);
  const [allProducts, setAllProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getProducts();
      setAllProducts(response?.data?.data);
      setFilteredProducts(response?.data?.data); // Initialize with all products
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // Filter products based on product type
  useEffect(() => {
    if (productTypeFilter) {
      const filtered = allProducts?.filter(
        (product) => product.productType === productTypeFilter
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(allProducts); // Show all products if no filter is selected
    }
  }, [productTypeFilter, allProducts]);

  const handleDelete = async (id) => {
    try {
      const response = await API.deleteProduct(id);
      successToast(response?.data?.message);
      getData();
    } catch (error) {
      errorToast("Can not delete Blog");
    }
  };

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Products"}
        previous={"Dashboard"}
        currentpage={"Products"}
        btntext={"Add Product"}
        btnlink={"add"}
      />
      <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
        <ResultFilterBar
          setitemPerPage={setitemPerPage}
          setfilterdata={setFilteredProducts}
          filterdata={filteredProducts}
          setSearchFilter={setSearchFilter}
        />
        {/* Dropdown for Product Type Filter */}
        <div className="mt-12 max-w-[300px] w-full">
          <label
            htmlFor="productTypeFilter"
            className="block text-lg font-semibold text-white mb-2"
          >
            Filter by Product Type:
          </label>
          <select
            id="productTypeFilter"
            value={productTypeFilter}
            onChange={(e) => setProductTypeFilter(e.target.value)}
            className="mt-1 block w-full rounded-lg border border-gray-300 shadow-md focus:ring-2 focus:ring-indigo-500 transition duration-200 ease-in-out text-gray-700 px-4 py-2"
          >
            <option value="CUSTOM">Custom</option>
            <option value="PHYSICAL">Physical</option>
          </select>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {filteredProducts && (
              <Tableform
                filterdata={filteredProducts}
                tablecolumns={productColumns}
                itemPerPage={itemPerPage}
                searchFilter={searchFilter}
                pagename={"edit"}
                preview={false}
                handleDelete={handleDelete}
                isDelete={true}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Products;
