const allmembercolumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email",
  },
   
  {
    key: "date",
    label: "Joint At",
  },
  {
    key: "isApproved",
    label: "Status",
  },
  {
    key: "actions",
    label: "Actions",
  },
];

export { allmembercolumn };
