import React, { useState, useEffect } from "react";
import { API } from "../../api";
import { blackLogo, Logo3 } from "../../assets";
import html2pdf from "html2pdf.js";

const Statement = () => {
  const [data, setData] = useState(null);
  const [balance, setBalance] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getPNL();
      setData(response?.data);
      const response2 = await API.getBalance();
      setBalance(response2?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!data || !balance) {
    return <div>Loading...</div>;
  }

  const downloadPDF = async () => {
    const element = document.getElementById("statement-container"); // This will be the container you want to convert to PDF
    const options = {
      filename: "Income_Statement.pdf",
      html2canvas: { scale: 2 }, // Optional settings to improve quality
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    html2pdf().from(element).set(options).save(); // Generate and download the PDF
  };

  return (
    <div className="bg-gray-50">
      <div
        style={{
          background: "#F7F7F7",
          padding: "50px",
          fontFamily: "Arial, sans-serif",
          lineHeight: "1.5",
        }}
        id="statement-container"
      >
        <div className="flex items-center justify-end">
          <img className="h-20" src={Logo3} alt="" />
        </div>
        <h2 className="text-[#ED1B24] font-bold">Income Statement</h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  borderBottom: "6px solid #ED1B24",
                  padding: "8px",
                }}
              >
                {/* Category */}
              </th>
              <th
                style={{
                  textAlign: "right",
                  borderBottom: "6px solid #ED1B24",
                  padding: "8px",
                }}
              >
                Amount (USD)
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Revenue */}
            <tr>
              <td style={{ padding: "8px", fontWeight: "bold" }}>Revenue</td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                {data.revenue.total.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ padding: "8px", paddingLeft: "20px" }}>
                Orders Revenue
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                {data.revenue.orders.toLocaleString()}
              </td>
            </tr>
            <tr>
              <td style={{ padding: "8px", paddingLeft: "20px" }}>
                Other Revenue
              </td>
              <td style={{ padding: "8px", textAlign: "right" }}>
                {data.revenue.otherRevenue.toLocaleString()}
              </td>
            </tr>

            {/* Cost of Goods Sold */}
            <tr>
              <td style={{ padding: "8px", borderBottom: "2px solid #ED1B24" }}>
                Cost of Goods Sold
              </td>
              <td
                style={{
                  padding: "8px",
                  borderBottom: "2px solid #ED1B24",
                  textAlign: "right",
                }}
              >
                {data.costOfGoodsSold.toLocaleString()}
              </td>
            </tr>

            {/* Gross Profit */}
            <tr>
              <td
                style={{ padding: "8px", fontWeight: "bold", color: "#ED1B24" }}
              >
                Gross Profit
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "#ED1B24",
                }}
              >
                {data.grossProfit.toLocaleString()}
              </td>
            </tr>

            {/* Expenses */}
            <tr>
              <td colSpan="2" style={{ padding: "8px", fontWeight: "bold" }}>
                Expenses
              </td>
            </tr>
            {data?.expenses?.list.map((expense, index) => (
              <tr key={index}>
                <td style={{ padding: "8px", paddingLeft: "20px" }}>
                  {expense.name}
                </td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  {expense.amount.toLocaleString()}
                </td>
              </tr>
            ))}
            <tr>
              <td
                style={{
                  padding: "8px",
                  fontWeight: "bold",
                  borderBottom: "2px solid #ED1B24",
                }}
              >
                Total Expenses
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  borderBottom: "2px solid #ED1B24",
                }}
              >
                {data.expenses.total.toLocaleString()}
              </td>
            </tr>

            {/* Net Profit */}
            <tr>
              <td
                style={{ padding: "8px", fontWeight: "bold", color: "#ED1B24" }}
              >
                Net Profit
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  color: data.netProfit >= 0 ? "#ED1B24" : "blue",
                }}
              >
                {data.netProfit.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>

        <h2 style={{ marginTop: "40px" }} className="text-[#ED1B24] font-bold">
          Balance Sheet
        </h2>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  borderBottom: "6px solid #ED1B24",
                  padding: "8px",
                }}
              >
                {/* Category */}
              </th>
              <th
                style={{
                  textAlign: "right",
                  borderBottom: "6px solid #ED1B24",
                  padding: "8px",
                }}
              >
                Amount (USD)
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Assets */}
            <tr>
              <td
                style={{
                  padding: "8px",
                  fontWeight: "bold",
                }}
              >
                Assets
              </td>
              <td></td>
            </tr>
            {balance.assets.list.map((asset, index) => (
              <tr key={index} style={{ borderBottom: "2px solid #ED1B24" }}>
                <td style={{ padding: "8px", paddingLeft: "20px" }}>
                  {asset.name}
                </td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  {asset.amount.toLocaleString()}
                </td>
              </tr>
            ))}
            <tr>
              <td
                style={{ padding: "8px", fontWeight: "bold", color: "#ED1B24" }}
              >
                Total Assets
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "#ED1B24",
                }}
              >
                {balance.assets.total.toLocaleString()}
              </td>
            </tr>

            {/* Liabilities */}
            <tr>
              <td style={{ padding: "8px", fontWeight: "bold" }}>
                Liabilities
              </td>
              <td></td>
            </tr>
            {balance.liabilities.list.map((liability, index) => (
              <tr key={index} style={{ borderBottom: "2px solid #ED1B24" }}>
                <td style={{ padding: "8px", paddingLeft: "20px" }}>
                  {liability.name}
                </td>
                <td style={{ padding: "8px", textAlign: "right" }}>
                  {liability.amount.toLocaleString()}
                </td>
              </tr>
            ))}
            <tr>
              <td
                style={{
                  padding: "8px",
                  fontWeight: "bold",
                  color: "#ED1B24",
                  borderBottom: "2px solid #ED1B24",
                }}
              >
                Total Liabilities
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "#ED1B24",
                  borderBottom: "2px solid #ED1B24",
                }}
              >
                {balance.liabilities.total.toLocaleString()}
              </td>
            </tr>

            {/* Equity */}
            <tr>
              <td
                style={{ padding: "8px", fontWeight: "bold", color: "#ED1B24" }}
              >
                Equity
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                  color: "#ED1B24",
                }}
              >
                {balance.equity.toLocaleString()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-end items-center p-10">
        <button
          onClick={downloadPDF}
          className="bg-[#ED1B24] text-white p-2 rounded mt-4"
        >
          Download PDF
        </button>
      </div>
    </div>
  );
};

export default Statement;
