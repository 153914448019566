export const orderStatusEnum = [
    {
      id: "DELIVERED",
      key: "DELIVERED",
    },
    {
      id: "PROCESSING",
      key: "PROCESSING",
    },
    {
      id: "PENDING",
      key: "PENDING",
    },
    {
      id: "CANCELLED",
      key: "CANCELLED",
    },
    {
      id: "OUTFORDELIVERY",
      key: "OUT FOR DELIVERY",
    },
  ];