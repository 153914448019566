import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import InputField from "./InputField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup"; // Import yupResolver
import { bulkEmailSchema } from "../../validations/bulkEmail";
import Editor from "./Editor";
import { errorToast, successToast } from "../../hooks/useToast";
import { API } from "../../api";

const EmailModal = () => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
    reset,
  } = useForm({ resolver: yupResolver(bulkEmailSchema) });

  const [loading, setLoading] = useState(false);
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await API.sendBulkEmail(data);
      successToast(response?.data?.message);
      reset();
      onClose();
    } catch (error) {
      errorToast(error, "Can not send email at the moment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className="bg-themeBtn-0 mt-6 py-4 px-2 rounded-lg min-w-[170px] flex items-center justify-center text-center text-white font-medium capitalize gap-1"
        onPress={onOpen}
      >
        Bulk Email
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={false}
        size="2xl"
        isKeyboardDismissDisabled={true}
      >
        <ModalContent>
          {(onClose) => (
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody>
                <InputField
                  label="Heading"
                  type="text"
                  placeholder="Please enter heading of email"
                  errors={errors}
                  name="heading"
                  register={register}
                />
                <InputField
                  label="Subject"
                  type="text"
                  placeholder="Please enter subject of email"
                  errors={errors}
                  name="subject"
                  register={register}
                />
                <div className="grid grid-col-1  ">
                  <Editor
                    label="Message"
                    errors={errors}
                    name="message"
                    register={register}
                    setValue={setValue}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button isLoading={loading} color="danger" type="submit">
                  Send Email
                </Button>
              </ModalFooter>
            </form>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailModal;
