import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../../components/general/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { errorToast, successToast } from "../../../hooks/useToast";
import { yupResolver } from "@hookform/resolvers/yup";
import Editor from "../../../components/general/Editor";
import { useQuery } from "../../../hooks/queryParam";
import { productScehma } from "../../../validations/productValidations";
import GeneralImageUpload from "../../../components/general/GeneralImageUpload";
import { itemsSchema } from "../../../validations/itemsValidations";
import * as yup from "yup";

const accountSchema = yup.object().shape({
  name: yup.string().required("Title is required"),
  amount: yup.string().required("Amount is required"),
  account_type: yup
    .string()
    .oneOf(
      ["ASSETS", "LIABILITY", "REVENUE", "EXPENSE"],
      "Invalid ACCOUNT type"
    )
    .required("Type is required"),
});

const EditAccount = () => {
  let query = useQuery();
  let id = query.get("id");
  const [product, setProduct] = useState(null);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm(
    { resolver: yupResolver(accountSchema) },
    {
      defaultValues: {
        name: product?.name || "",
        amount: product?.amount || "",
        account_type: product?.account_type || "",
      },
    }
  );

  console.log(product, "product");

  useEffect(() => {
    if (product) {
      setValue("name", product?.name);
      setValue("amount", product?.amount);
      setValue("account_type", product?.account_type);
    }
  }, [product]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let response;

      await API.updateAccount(id, {
        ...data,
        amount: Number(data.amount),
      });

      successToast("Successfully updated");
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Cannot upload product");
      console.error("Error uploading images:", error);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getData = async () => {
    try {
      const response = await API.getSingleAccount(id);
      setProduct(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setValue("imageUrl", product?.imageUrl);
  }, [product]);

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Products"}
        previous={"Dashboard"}
        currentpage={"Edit Product"}
      />
      {product && (
        <form
          className="grid grid-col-1 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
            <InputField
              label="Title"
              type="text"
              placeholder="Product Title"
              errors={errors}
              name="name"
              register={register}
              defaultValue={product?.name}
            />
            <InputField
              label="Amount"
              type="text"
              placeholder="Enter Amount"
              errors={errors}
              name="amount"
              register={register}
              defaultValue={product?.amount}
            />

            <InputField
              label="Type"
              type="select"
              placeholder="Select type of your item"
              errors={errors}
              options={["ASSETS", "LIABILITY", "REVENUE", "EXPENSE"]}
              name="account_type"
              register={register}
              defaultValue={product?.account_type}
            />

            <div className="w-full md:w-1/4 mt-4">
              <div className="flex gap-3">
                <ButtonComponent
                  type="submit"
                  text="Save"
                  loading={loading}
                  isActive={true}
                />
                <ButtonComponent
                  text="Cancel"
                  isActive={true}
                  btnclass={"bg-red-500"}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditAccount;
