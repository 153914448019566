import React, { useEffect, useState } from "react";
import { useQuery } from "../../../hooks/queryParam";
import { API } from "../../../api";
import { errorToast } from "../../../hooks/useToast";
import Header from "../../../components/dashboard/Header";

const OrderPreview = () => {
  const query = useQuery();
  const id = query.get("id");

  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  const fetchData = async () => {
    try {
      const response = await API.userOrderIndividual(id);
      setOrderData(response?.data?.data);
    } catch (error) {
      errorToast(error, "Failed to load order data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="page-area mt-10 min-h-screen">
      <Header
        pagetitle="Order Preview"
        previous="Dashboard"
        currentpage="Order Details"
      />

      {orderData ? (
        <>
          {orderData?.map((a) => (
            <div className="bg-white rounded-xl my-4 shadow-lg p-8">
              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4 text-gray-800">
                  Order Items
                </h3>
                {a?.orderItems?.map((item) => (
                  <div
                    key={item.id}
                    className="flex justify-between items-center gap-6 bg-gradient-to-r from-teal-500 to-emerald-500 rounded-xl w-full py-4 px-6 shadow-lg mb-4"
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={item?.imageUrl}
                        alt={item?.name}
                        className="w-16 h-16 object-cover rounded-lg shadow-md"
                      />
                      <div>
                        <p className="text-white text-lg font-semibold">
                          {item?.name}
                        </p>
                        {item?.quantity && (
                          <p className="text-white text-md font-light">
                            Quantity: {item?.quantity}
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      <p className="text-white text-lg font-semibold">
                        Price: ${item?.price}
                      </p>
                      {item?.subTotal && (
                        <p className="text-white text-lg font-semibold">
                          SubTotal: ${item?.subTotal}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="bg-gradient-to-r from-purple-600 to-indigo-600 rounded-xl w-full py-6 px-8 shadow-lg mt-6 text-white">
                <p className="text-xl font-semibold">Total Price</p>
                <p className="text-3xl font-bold mt-2">
                  ${a?.totalAndDelivery}
                </p>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>NO orders yet!</>
      )}
    </div>
  );
};

export default OrderPreview;
