import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../../components/general/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { errorToast, successToast } from "../../../hooks/useToast";
import { yupResolver } from "@hookform/resolvers/yup";
import Editor from "../../../components/general/Editor";
import { useQuery } from "../../../hooks/queryParam";
import { productScehma } from "../../../validations/productValidations";
import GeneralImageUpload from "../../../components/general/GeneralImageUpload";
import { virtualSchema } from "../../../validations/virtualValidations";

const EditVirtual = () => {
  let query = useQuery();
  let id = query.get("id");
  const [product, setProduct] = useState(null);
  const [image, setImage] = useState(null);
  const [content, setContent] = useState(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm(
    { resolver: yupResolver(virtualSchema) },
    {
      defaultValues: {
        name: product?.name || "",
        slug: product?.slug || "",
        shortDescription: product?.shortDescription || "",
        longDescription: product?.longDescription || "",
        ingredients: product?.ingredients || "",
        imageUrl: product?.imageUrl,
        downloadUrl: product?.downloadUrl,
      },
    }
  );

  useEffect(() => {
    if (product) {
      setValue("shortDescription", product?.shortDescription);
      setValue("name", product?.name);
      setValue("slug", product?.slug);
      setValue("longDescription", product?.longDescription);
      setValue("ingredients", product?.ingredients);
      setValue("entries", product?.entries);
      setValue("price", product?.price);
      setValue("costPrice", product?.costPrice);
      setValue("stock", product?.stock);
      setValue("discountedPrice", product?.discountedPrice);
    }
  }, [product]);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      let imageResponse = null;
      let contentResponse = null;

      // Handle image upload
      try {
        if (image) {
          const formData = new FormData();
          formData.append("images", image);
          imageResponse = await API.uploadImage(formData);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        errorToast("Failed to upload product image.");
      }

      // Handle content upload
      try {
        if (content) {
          const formData = new FormData();
          formData.append("images", content);
          contentResponse = await API.uploadImage(formData);
        }
      } catch (error) {
        console.error("Error uploading content:", error);
        errorToast("Failed to upload product content.");
      }

      // Prepare updated product data
      const updatedProduct = {
        ...data,
        ...(imageResponse && { imageUrl: imageResponse?.data?.data?.[0] }),
        ...(contentResponse && {
          downloadUrl: contentResponse?.data?.data?.[0],
        }),
      };

      // Update product
      const response = await API.updateProduct(id, updatedProduct);

      // Success handling
      successToast(response?.data?.message);
      navigate(-1);
    } catch (error) {
      // Error handling for the product update operation
      console.error("Error updating product:", error);
      errorToast("Failed to update product. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getData = async () => {
    try {
      const response = await API.getSingleProduct(id);
      setProduct(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setValue("imageUrl", product?.imageUrl);
    setValue("downloadUrl", product?.downloadUrl);
  }, [product]);

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Products"}
        previous={"Dashboard"}
        currentpage={"Edit Product"}
      />
      {product && (
        <form
          className="grid grid-col-1 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
            <InputField
              label="Title"
              type="text"
              placeholder="Product Title"
              errors={errors}
              name="name"
              register={register}
              defaultValue={product?.name}
            />

            <div className="grid grid-col-1 gap-4 mt-8 mb-4">
              <GeneralImageUpload
                heading={"Upload Image"}
                image={image}
                setImage={setImage}
                name="imageUrl"
                errors={errors}
                register={register}
                setValue={setValue}
                defaultImage={product?.imageUrl}
              />
            </div>
            <div className="grid grid-col-1 gap-4 mt-8 mb-4">
              <GeneralImageUpload
                heading={"Downloadable Content"}
                setImage={setContent}
                name="downloadUrl"
                errors={errors}
                register={register}
                setValue={setValue}
                isDownloadable={true}
                defaultImage={product?.downloadUrl}
              />
            </div>
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 mt-4">
              <InputField
                label="Slug"
                type="text"
                placeholder="slug of product"
                errors={errors}
                name="slug"
                register={register}
                defaultValue={product?.slug}
              />
              <InputField
                label="Entries"
                type="number"
                placeholder="Enter total entries"
                errors={errors}
                name="entries"
                register={register}
                defaultValue={product?.entries}
              />
            </div>
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 mt-4">
              <InputField
                label="Price"
                type="text"
                placeholder="Enter selling price of product"
                errors={errors}
                name="price"
                register={register}
                defaultValue={product?.price}
              />
              <InputField
                label="Discount Price"
                type="text"
                placeholder="Enter discounted price of product"
                errors={errors}
                name="discountedPrice"
                register={register}
                defaultValue={product?.discountedPrice}
              />
            </div>
            <div className="grid grid-col-1 sm:grid-cols-2 gap-4 mt-4">
              <InputField
                label="Cost Price"
                type="text"
                placeholder="Enter purchased price of product"
                errors={errors}
                name="costPrice"
                register={register}
                defaultValue={product?.costPrice}
              />
              <InputField
                label="Total Stock"
                type="text"
                placeholder="Enter stick of product"
                errors={errors}
                name="stock"
                register={register}
                defaultValue={product?.stock}
              />
            </div>
            <div className="grid grid-col-1  gap-4  mt-8 mb-4">
              <Editor
                label="Short Description"
                errors={errors}
                name="shortDescription"
                register={register}
                setValue={setValue}
                defaultValue={product?.shortDescription}
              />
            </div>
            <div className="grid grid-col-1  gap-4  mt-8 mb-4">
              <Editor
                label="Long Description"
                errors={errors}
                name="longDescription"
                register={register}
                setValue={setValue}
                defaultValue={product?.longDescription}
              />
            </div>

            <div className="w-full md:w-1/4 mt-4">
              <div className="flex gap-3">
                <ButtonComponent
                  type="submit"
                  text="Save"
                  loading={loading}
                  isActive={true}
                />
                <ButtonComponent
                  text="Cancel"
                  isActive={true}
                  btnclass={"bg-red-500"}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditVirtual;
