import { useEffect, useState } from "react";
import Header from "../../components/dashboard/Header";
import ResultFilterBar from "../../components/general/ResultFilterBar";
import Tableform from "../../components/general/Tableform";
import { API } from "../../api";
import { errorToast, successToast } from "../../hooks/useToast";
import Loader from "../../components/general/Loader";
import { itemsColumns } from "../../data/itemsColumns";

const Accounts = () => {
  const [itemPerPage, setitemPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState(null);

  const [loading, setLoading] = useState(true);
  const [allItems, setAllItems] = useState(null);

  const getData = async () => {
    try {
      const response = await API.getAllAccounts();
      setAllItems(response?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Can not fetch data");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await API.deleteAccount(id);
      successToast(response?.data?.message);
      getData();
    } catch (error) {
      errorToast("Can not delete Blog");
    }
  };

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Items"}
        previous={"Dashboard"}
        currentpage={"Products"}
        btntext={"Add Item"}
        btnlink={"add-account"}
      />
      <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
        <ResultFilterBar
          setitemPerPage={setitemPerPage}
          setfilterdata={setAllItems}
          filterdata={allItems}
          setSearchFilter={setSearchFilter}
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            {allItems && (
              <Tableform
                filterdata={allItems}
                tablecolumns={[
                  {
                    key: "num",
                    label: "SN.",
                  },
                  { key: "id", label: "Id" },
                  { key: "name", label: "Name" },
                  { key: "account_type", label: "Account Type" },
                  { key: "amount", label: "Amount" },
                  {
                    key: "actions",
                    label: "Actions",
                  },
                ]}
                itemPerPage={itemPerPage}
                searchFilter={searchFilter}
                pagename={"edit-account"}
                preview={false}
                handleDelete={handleDelete}
                isDelete={true}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Accounts;
