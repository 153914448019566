const orderColumn = [
  {
    key: "num",
    label: "SN.",
  },
  {
    key: "totalAndDelivery",
    label: "Amount",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "userName",
    label: "User",
  },
  {
    key: "userPhone",
    label: "Contact",
  },
  {
    key: "userEmail",
    label: "Email",
  },
  {
    key: "totalItems",
    label: "Total Items",
  },
  {
    key: "orderStatus",
    label: "status",
  },

  {
    key: "actions",
    label: "Actions",
  },
];

const orderHistoryColumn = [
  {
    key: "num",
    label: "SN.",
  },

  {
    key: "userName",
    label: "User",
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "phoneNumber",
    label: "Phone Number",
  },
  {
    key: "orders",
    label: "Total Orders",
  },
  // {
  //   key: "isBlocked",
  //   label: "Active",
  // },

  {
    key: "actions",
    label: "Actions",
  },
];
export { orderColumn , orderHistoryColumn };
