import React, { useEffect, useState } from "react";
import Header from "../../../components/dashboard/Header";
import InputField from "../../../components/general/InputField";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../../components/general/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { API } from "../../../api";
import { errorToast, successToast } from "../../../hooks/useToast";
import { yupResolver } from "@hookform/resolvers/yup";
import Editor from "../../../components/general/Editor";
import { useQuery } from "../../../hooks/queryParam";
import { productScehma } from "../../../validations/productValidations";
import GeneralImageUpload from "../../../components/general/GeneralImageUpload";
import { itemsSchema } from "../../../validations/itemsValidations";

const EditItems = () => {
  let query = useQuery();
  let id = query.get("id");
  const [product, setProduct] = useState(null);
  const [image, setImage] = useState(null);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm(
    { resolver: yupResolver(itemsSchema) },
    {
      defaultValues: {
        name: product?.name || "",
        description: product?.description || "",
        item_type: product?.description || "",
        imageUrl: product?.imageUrl,
      },
    }
  );

  console.log(product, "product");

  useEffect(() => {
    if (product) {
      setValue("name", product?.name);
      setValue("description", product?.description);
      setValue("item_type", product?.item_type);
    }
  }, [product]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let response;
      if (image) {
        const formData = new FormData();
        formData.append("images", image);

        response = await API.uploadImage(formData);
        await API.updateProduct(id, {
          ...data,
          imageUrl: response?.data?.data[0],
        });
      } else {
        response = await API.updateItem(id, data);
      }

      successToast(response?.data?.message);
      setLoading(false);
      navigate(-1);
    } catch (error) {
      setLoading(false);
      errorToast(error, "Cannot upload product");
      console.error("Error uploading images:", error);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getData = async () => {
    try {
      const response = await API.getSingleItem(id);
      setProduct(response?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setValue("imageUrl", product?.imageUrl);
  }, [product]);

  return (
    <div className="page-area mt-10">
      <Header
        pagetitle={"Products"}
        previous={"Dashboard"}
        currentpage={"Edit Product"}
      />
      {product && (
        <form
          className="grid grid-col-1 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="page-comp bg-white mt-10 rounded-xl px-8 py-8">
            <InputField
              label="Title"
              type="text"
              placeholder="Product Title"
              errors={errors}
              name="name"
              register={register}
              defaultValue={product?.name}
            />

            <InputField
              label="Type"
              type="select"
              placeholder="Select type of your item"
              errors={errors}
              options={["CANDY", "CHIPS", "COOKIE", "DRINK"]}
              name="item_type"
              register={register}
              defaultValue={product?.item_type}
            />

            <div className="grid grid-col-1 gap-4 mt-8 mb-4">
              <GeneralImageUpload
                heading={"Upload Image"}
                image={image}
                setImage={setImage}
                name="imageUrl"
                errors={errors}
                register={register}
                setValue={setValue}
                defaultImage={product?.imageUrl}
              />
            </div>

            <div className="grid grid-col-1  gap-4  mt-8 mb-4">
              <Editor
                label="Description"
                errors={errors}
                name="description"
                register={register}
                setValue={setValue}
                defaultValue={product?.description}
              />
            </div>

            <div className="w-full md:w-1/4 mt-4">
              <div className="flex gap-3">
                <ButtonComponent
                  type="submit"
                  text="Save"
                  loading={loading}
                  isActive={true}
                />
                <ButtonComponent
                  text="Cancel"
                  isActive={true}
                  btnclass={"bg-red-500"}
                  onClick={handleCancel}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditItems;
